.project {
    width: 100%;
    background-color: $color-primary;
    color: $color-bg;
    display: flex;
    justify-content: center;

    &__wrapper {
        width: 100%;
        max-width: $dim-breakpoint-lg;
        display: flex;
        flex-direction: column;
        padding: 6rem 2rem;
        align-items: center;
    }

    &__center {
        width: 80%;
    }

    &__header {
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: bold;
        padding-bottom: 1rem;

        @media (min-width: $dim-breakpoint-md-lg) {
            font-size: 3rem;
        }
    }

    &__text {
        font-size: 1.5rem;
        padding-bottom: 2rem;
        @media (min-width: $dim-breakpoint-md-lg) {
            font-size: 1.8rem;
        }

        @media (min-width: $dim-breakpoint-lg) {
            width: 70%;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem 0;

        &__header {
            font-size: 2rem;
            text-transform: uppercase;
            font-weight: bold;
            padding-bottom: 1rem;

            @media (min-width: $dim-breakpoint-md-lg) {
                font-size: 3rem;
            }
        }

        &__subheader {
            font-size: 1.7rem;
            font-weight: bold;
            padding-bottom: 1rem;

            @media (min-width: $dim-breakpoint-md-lg) {
                font-size: 3rem;
            }
        }
    }

    &-input {
        &-row {
            width: 100%;
            display: flex;
            flex-direction: column;

            @media (min-width: $dim-breakpoint-md) {
                flex-direction: row;
                justify-content: space-between;
            }

            &.big {
                flex-direction: column;
            }
        }

        &-cell {
            width: 100%;
            display: flex;
            flex-direction: column;

            @media (min-width: $dim-breakpoint-md) {
                flex: 0 0 48%;
            }

            &__header {
                font-size: 1.5rem;
                text-transform: uppercase;
                font-weight: bold;
                padding: 1rem 0;
                @media (min-width: $dim-breakpoint-md-lg) {
                    font-size: 2rem;
                }
            }

            &__input {
                padding: 0.5rem 1rem;
                border-radius: 0.5rem;
                border: none;
                outline: none;
                font-size: 1.8rem;
            }

            &__textarea {
                padding: 0.5rem 1rem;
                border: none;
                outline: none;
                border-radius: 0.5rem;
                min-height: 14rem;
                font-size: 1.8rem;

                @media (min-width: $dim-breakpoint-md) {
                    min-height: 20rem;
                }
            }
        }
    }

    &-buttons {
        padding-top: 1rem;
    }
}
