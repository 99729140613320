.about {
    width: 100%;
    background-color: $color-primary;
    color: $color-bg;
    height: 100%;
    padding: 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $dim-breakpoint-md) {
        padding: 8rem 0;
    }

    &__wrapper {
        max-width: $dim-breakpoint-lg;
        display: flex;
        flex-direction: column;
        padding: 4rem 2rem;

        @media (min-width: $dim-breakpoint-md) {
            flex-direction: row;
        }
    }

    &__left {
        display: flex;
        flex-direction: column;

        @media (min-width: $dim-breakpoint-md) {
            flex: 0 0 60%;
            padding-left: 10%;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        @media (min-width: $dim-breakpoint-md) {
            flex: 0 0 40%;
        }
    }

    &__row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-bottom: 2rem;
        align-items: center;
    }

    &__header {
        display: flex;
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: bold;

        @media (min-width: $dim-breakpoint-md-lg) {
            font-size: 3rem;
        }

        &.paddingbottom {
            padding-bottom: 2rem;
        }

        &.row {
            flex: 0 0 20%;
            @media (min-width: $dim-breakpoint-md-lg) {
                flex: 0 0 30%;
                justify-content: flex-end;
                padding-right: 2rem;
                align-items: center;
            }
        }
    }

    &__text {
        display: flex;
        font-size: 1.5rem;
        text-align: left;
        justify-content: flex-start;
        @media (min-width: $dim-breakpoint-md) {
            font-size: 1.7rem;
        }

        &.row {
            flex: 0 0 30%;

            @media (min-width: $dim-breakpoint-md) {
                flex: 0 0 60%;
            }
        }

        &.paddingbottom {
            padding-bottom: 2rem;
        }
    }
}
