.button {
    background-color: $color-secondary;
    color: $color-bg;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bolder;
    border: none;
    outline: none;
    padding: 1rem 2rem;
    border-radius: 0.75rem;
    
}