.whotrusted {
    width: 100%;
    display: flex;
    justify-content: center;

    &__wrapper {
        display: flex;
        width: 100%;
        max-width: $dim-breakpoint-lg;
        justify-content: center;
        padding: 2rem;
    }

    &__center {
        display: flex;
        width: 80%;
        align-items: center;
        flex-direction: column;
    }

    &__header {
        width: 100%;
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-primary;
        padding-bottom: 2rem;

        @media(min-width: $dim-breakpoint-md-lg) {
            font-size: 3rem;
        }
    }

    &__container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &__cell {
            width: 100%;
            display: flex;
            flex: 0 0 100%;
            padding: 1.5rem 0;

            @media (min-width: $dim-breakpoint-sm) {
                flex: 0 0 50%;
                padding: 1.5rem 1rem;
            }
            @media (min-width: $dim-breakpoint-md) {
                flex: 0 0 1/3 * 100%;
                padding: 1.5rem 1.5rem;
            }

            @media(min-width: $dim-breakpoint-md-lg) {
                padding: 3rem 3rem;
            }

            &-img {
                display: flex;
                width: 100%;
            }
        }
    }
}
