.contact {
    width: 100%;
    background-color: $color-primary;
    color: $color-bg;
    display: flex;
    justify-content: center;

    &__wrapper {
        max-width: $dim-breakpoint-lg;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 6rem 2rem 1rem;
    }

    &__center {
        display: flex;
        width: 80%;
        align-items: center;
        flex-direction: column;
    }

    &__header {
        width: 100%;
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: bold;
        padding-bottom: 1rem;

        @media (min-width: $dim-breakpoint-md-lg) {
            font-size: 3rem;
        }
    }

    &__text {
        width: 100%;
        padding-bottom: 1rem;
        font-size: 1.5rem;

        &.bigpadding {
            padding-bottom: 3rem;
        }

        @media (min-width: $dim-breakpoint-md-lg) {
            font-size: 1.7rem;
        }
    }

    &__footer {
        width: 100%;
        padding-top: 5rem;
    }
}
