.start {
    padding-top: 8rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;

    &__wrapper {
        width: 100%;
        max-width: $dim-breakpoint-lg;

        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        width: 100%;
        padding: 2rem;
        align-items: flex-start;

        @media (min-width: $dim-breakpoint-md) {
            position: absolute;
            width: auto;
            align-items: flex-start;
            top: 25%;
            left: 5%;
        }

        @media (min-width: $dim-breakpoint-md-lg) {
            top: 31%;
            left: 13%;
        }

        &__primary {
            font-size: 2.5rem;
            font-weight: bold;
            color: $color-primary;

            @media (min-width: $dim-breakpoint-md-lg) {
                font-size: 3rem;
            }

            &.secondary {
                color: $color-secondary;
            }
        }

        &__button {
            padding-top: 1rem;

            @media (min-width: $dim-breakpoint-md) {
                padding-top: 3rem;
            }
        }
    }

    &__image {
        width: 100%;
        padding-top: 100%;
        background-repeat: no-repeat;
        background-image: url("../../assets/programmer-sketch.png");
        position: relative;
        background-position: 50% 0%;
        background-size: auto 70%;

        @media (min-width: $dim-breakpoint-md) {
            background-position: 100% 20%;
            background-size: auto 50%;
        }

        @media (min-width: $dim-breakpoint-md-lg) {
            padding-top: 70%;

            background-size: auto 60%;
            background-position: 85% 25%;
        }
    }

    //background-color: $color-grey-1;
}
