._nav {
    background-color: $color-bg;
    height: 8rem;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    box-shadow: $box-shadow-1;
    z-index: 10;

    &__center {
        width: 100%;
        max-width: $dim-breakpoint-lg;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__logo {
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
        padding: 2rem;
        height: 100%;

        &-img {
            height: 100%;
        }
    }

    &__right {
        flex: 1 0 10%;
        display: flex;
        justify-content: flex-end;
        height: 8rem;

        &-button {
            padding: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Material Icons, sans-serif;
            font-size: 3rem;
            color: $color-primary;
        }

        @media (min-width: $dim-breakpoint-md) {
            display: none;
        }
    }

    &__links {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0 60%;
        background-color: $color-bg;

        @media (min-width: $dim-breakpoint-md) {
            display: flex;
            flex-direction: row;
        }
    }

    &__link {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        text-align: center;
        color: $color-primary;
        padding: 1rem;
        outline: none;
        cursor: pointer;

        @media (min-width: $dim-breakpoint-md-lg) {
            padding: 1rem 2rem;
        }
    }

    &.is-open {
        ._nav {
            &__links {
                display: flex;
                border-bottom: 1px solid $color-primary;

                @media (min-width: $dim-breakpoint-md) {
                    border-bottom: none;
                }
            }

            &__link {
                font-size: 1.5rem;

                @media (min-width: $dim-breakpoint-md) {
                    font-size: 1.3rem;
                }
            }
        }
    }
}
